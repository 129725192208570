import React, { useEffect } from "react";
import { useState } from "react";

import LoadingComponent from "../../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
import {
  alumnoQuitarTutorPrincipal,
  getAlumnosRegistradosReducida,
  getGrupos,
  getPlantillasEntregasAsignables,
  getSedesAsignables,
  update_grupo_alumno,
  update_plantilla_alumno,
  update_sede_alumno,
  update_tutor,
  update_tutor_exposicion,
} from "../../../../services/alumnos";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import { getTutores } from "../../../../services/tutores";
import { DataGridPro, GridActionsCellItem, GridToolbar, esES } from "@mui/x-data-grid-pro";

import CargaAlumnosTutor from "../../../../components/CargaAlumnosTutor";
import TituloSeccion from "../../../../components/Titulos/TituloSeccion";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { BASE_URL_SERVICE } from "../../../../constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PlantillasPermisosService } from "../../../../services/PlantillasPermisos.service";
import Swal from "sweetalert2";
import useDataGridFilterPersist from "../../../../hooks/useDataGridFilterPersist";

export default function AsignacionMasivaContent() {
  const [alumnos, setAlumnos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [tutores, setTutores] = useState([]);
  const [plantillasAsignables, setPlantillasAsignables] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [sedesAsignables, setSedesAsignables] = useState([]);
  const [plantillaPermisos, setPlantillasPermisos] = useState()

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando alumnos");

    const usuario_id = usuarioSeleccionado.id;

    getAlumnosRegistradosReducida({ usuario_id }).then((response) => {
      const alumnosRegistrados = response.alumnos.filter(
        (item) => item.status_id === "94"
      );
      response && setAlumnos(alumnosRegistrados);

      setLoading(false);
    });

    getTutores({ usuario_id: usuario_id }).then((response) => {
      response.tutores && setTutores(response.tutores);
    });

    PlantillasPermisosService.list({usuario_id: usuario_id}).then( response => {
      response.plantillas && setPlantillasPermisos(response.plantillas)
    })

    getGrupos({
      usuario_id: usuario_id,
    }).then((response) => {
      response.grupos && setGrupos(response.grupos);
    });

    getSedesAsignables({ usuario_id: usuario_id }).then((response) => {
      response.sedes && setSedesAsignables(response.sedes);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */

  const columnas = [
    { field: "id", hide: true, headerName: "ID" },
    {
      field: "nombre",
      headerName: "Nombre",
      minWidth: 150,
      flex: 1,
      renderCell: (celda) => {
        return (
          <Link href={`/alumnos_tutor/detalle/${celda.row.id}`}>
            {celda.row.nombre}
          </Link>
        );
      },
    },
    {
      field: "comunidad_autonoma_nombre",
      headerName: "Comunidad",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "especialidad_nombre",
      headerName: "Especialidad",
      minWidth: 10,
      flex: 1,
    },
    { field: "tutor_nombre", headerName: "Tutor", minWidth: 10, flex: 1 },
    {
      field: "plantilla_permisos_nombre",
      headerName: "Permisos",
      width: 350,
    },
    {
      field: "grupo_descripcion",
      headerName: "Grupo",
      width: 350,
    },
    // { field: "exposicion_oral_sede_nombre", headerName: "Sede", width: 100 },
    // {
    //   field: "es_alumno_fast",
    //   headerName: "FAST",
    //   minWidth: 130,
    //   type: "boolean",
    //   valueGetter: (celda) => {
    //     if (celda.value === "1") {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   renderCell: (celda) => {
    //     if (celda.value === true) {
    //       return <>SI</>;
    //     } else {
    //       return <>NO</>;
    //     }
    //   },
    // },
    // {
    //   field: "tutor_exposicion_nombre",
    //   headerName: "Tutor exposicion",
    //   minWidth: 10,
    //   flex: 1,
    // },
    {
      field: "productos",
      headerName: "Productos comprados",
      minWidth: 10,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<EditIcon />}
          label="Quitar tutor principal"
          onClick={ () => { 
            alumnoQuitarTutorPrincipal({usuario_id: usuarioSeleccionado.id, alumno_id: params.row.id}).then( response => {
              getAlumnosRegistradosReducida({ usuario_id: usuarioSeleccionado.id }).then((response) => {

                Swal.fire("El tutor principal ha sido quitado", "", "success");
                
                const alumnosRegistrados = response.alumnos.filter(
                  (item) => item.status_id === "94"
                );
                response && setAlumnos(alumnosRegistrados);
              });
            })
          }}
          showInMenu
        />,
      ],
    }
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */

  const Contenido = () => {
    const [tutorSeleccionado, setTutorSeleccionado] = useState("");
    // const [plantillaSeleccionada, setPlantillaSeleccionada] = useState("");
    const [grupoSeleccionado, setGrupoSeleccionado] = useState("");
    const [plantillaPermisosSeleccionada, setPlantillaPermisosSeleccionada] = useState("");
    const [sedeSeleccionada, setSedeSeleccionada] = useState("");
    const [selection, setSelection] = React.useState([]);
    const [loadingDatagrid, setLoadingDatagrid] = React.useState(false);

    const handleAsignarTutor = (tutorSeleccionado) => {
      let usuario_id = usuarioSeleccionado.id;
      if (tutorSeleccionado !== "") {
        selection.map((alumno_id) => {
          update_tutor({
            usuario_id: usuario_id,
            alumno_id: alumno_id,
            tutor_id: tutorSeleccionado,
          }).then((response) => {
            getAlumnosRegistradosReducida({ usuario_id }).then((response) => {
              const alumnosRegistrados = response.alumnos.filter(
                (item) => item.status_id === "94"
              );
              response && setAlumnos(alumnosRegistrados);
            });
          });
          return null;
        });
      } else {
        console.log("Sin tutor seleccionado");
      }
    };
    const handleAsignarTutorExposicion = (tutorSeleccionado) => {
      let usuario_id = usuarioSeleccionado.id;
      if (tutorSeleccionado !== "") {
        setLoadingDatagrid(true);
        selection.map((alumno_id) => {
          update_tutor_exposicion({
            usuario_id: usuario_id,
            alumno_id: alumno_id,
            tutor_id: tutorSeleccionado,
          }).then((response) => {
            getAlumnosRegistradosReducida({ usuario_id }).then((response) => {
                setLoadingDatagrid(false);
              const alumnosRegistrados = response.alumnos.filter(
                (item) => item.status_id === "94"
              );
              response && setAlumnos(alumnosRegistrados);
            });
          });
          return null;
        });
      } else {
        console.log("Sin tutor seleccionado");
      }
    };
    const [filterModel, onFilterModelChange] = useDataGridFilterPersist('asignacion_masiva_page','asignacion_masiva')

    const handleAsignarGrupo = (grupoSeleccionado) => {
      let usuario_id = usuarioSeleccionado.id;
      if (grupoSeleccionado !== "") {
        selection.map((alumno_id) => {
          update_grupo_alumno({
            usuario_id: usuario_id,
            alumno_id: alumno_id,
            grupo_id: grupoSeleccionado,
          }).then((response) => {
            getAlumnosRegistradosReducida({ usuario_id }).then((response) => {
              const alumnosRegistrados = response.alumnos.filter(
                (item) => item.status_id === "94"
              );
              response && setAlumnos(alumnosRegistrados);
            });
          });
          return null;
        });
      } else {
        console.log("Sin grupo seleccionado");
      }
    };

    const handleAsignarPlantillaPermisos = (plantillaPermisosSeleccionada) => {
      let usuario_id = usuarioSeleccionado.id;

      if (plantillaPermisosSeleccionada !== "") {
        selection.map((alumno_id) => {
          PlantillasPermisosService.alumnoAsignarPlantillaPermisos({usuario_id: usuario_id, alumno_id: alumno_id, plantilla_permisos_id: plantillaPermisosSeleccionada}).then((response) => {
            getAlumnosRegistradosReducida({ usuario_id }).then((response) => {
              const alumnosRegistrados = response.alumnos.filter(
                (item) => item.status_id === "94"
              );
              response && setAlumnos(alumnosRegistrados);
            });
          });
        });
      } else {
        console.log("Sin plantilla de permisos seleccionada");
      }
    };

    const handleAsignarSede = (sedeSeleccionada) => {
      let usuario_id = usuarioSeleccionado.id;
      if (sedeSeleccionada !== "") {
        selection.map((alumno_id) => {
          update_sede_alumno({
            usuario_id: usuario_id,
            alumno_id: alumno_id,
            sede_id: sedeSeleccionada,
          }).then((response) => {
            getAlumnosRegistradosReducida({ usuario_id }).then((response) => {
              const alumnosRegistrados = response.alumnos.filter(
                (item) => item.status_id === "94"
              );
              response && setAlumnos(alumnosRegistrados);
            });
          });
          return null;
        });
      } else {
        console.log("Sin sede seleccionada");
      }
    };

    const [anchorElOpciones, setAnchorElOpciones] = React.useState(null);
    const open = Boolean(anchorElOpciones);

    const handleClickOpciones = (event) => {
      setAnchorElOpciones(event.currentTarget);
    };
    const handleCloseOpciones = () => {
      setAnchorElOpciones(null);
    };

    const serialize = function (obj) {
      var str = [];
      //   if (obj.hasOwnProperty(p)) {
      //     str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      //   }
      for (var p in obj) return str.join("&");
    };

    return loading === false ? (
      <>
        <TituloSeccion titulo={"Asignación masiva"} />

        <Box mx={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ fontWeight: 500, marginTop: 5, marginRight: 10 }}
                >
                  Carga de los tutores
                </Typography>
                <Typography variant="subtitle1" style={{}}>
                  (Desplegar para ver)
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <CargaAlumnosTutor />
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box mx={2}>
          <Paper
            elevation={2}
            style={{ marginTop: 20, padding: "10px 0 10px 0px" }}
          >
            <Stack direction="row" mb={3} mx={2}>
              <Box>
                <Typography
                  variant="h5"
                  component="h3"
                  gutterBottom
                  style={{ fontWeight: 500, marginTop: 5 }}
                >
                  Alumnos registrados
                </Typography>
              </Box>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClickOpciones}
              >
                <MoreVertIcon />
              </IconButton>

              <Menu
                id="long-menu"
                anchorEl={anchorElOpciones}
                keepMounted
                open={open}
                onClose={handleCloseOpciones}
              >
                <MenuItem
                  href={`${BASE_URL_SERVICE}alumno/alumnos_exportar_correos_seleccionados?sel=${selection.join(
                    ","
                  )}`}
                  target="_blank"
                  component="a"
                  key="Opcion1"
                  onClick={() => {
                    handleCloseOpciones();
                  }}
                >
                  Exportar correos seleccionados
                </MenuItem>
              </Menu>
            </Stack>

            <Grid
              container
              xs={12}
              style={{ margin: 0, padding: "0 10px" }}
              spacing={2}
            >
              <Grid item xs={12} md={6} lg={3} style={{}}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="tutor_id-label">Tutores</InputLabel>

                  <Select
                    labelId="tutor_id-label"
                    id="tutor_id"
                    name="tutor_id"
                    label="Tutor"
                    fullWidth
                    value={tutorSeleccionado}
                    onChange={(value) => {
                      setTutorSeleccionado(value.target.value);
                    }}
                  >
                    {tutores &&
                      tutores.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nombre} {item.apellidos}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3} style={{}}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                  style={{ height: 56 }}
                  onClick={() => handleAsignarTutor(tutorSeleccionado)}
                >
                  Asignar tutor
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                  style={{ marginTop: 5, height: 56 }}
                  onClick={() =>
                    handleAsignarTutorExposicion(tutorSeleccionado)
                  }
                >
                  Asignar tutor exposicion
                </Button> */}
              </Grid>

              <Grid item xs={12} md={6} lg={3} style={{}}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    style={{ paddingLeft: 20 }}
                    id="grupo_id-label"
                  >
                    Grupo de alumnos
                  </InputLabel>

                  <Select
                    labelId="grupo_id-label"
                    id="grupo_id"
                    name="grupo_id"
                    label="Grupo"
                    fullWidth
                    value={grupoSeleccionado}
                    onChange={(value) => {
                      setGrupoSeleccionado(value.target.value);
                    }}
                  >
                    {grupos &&
                      grupos.map((item) => {
                        // return (<MenuItem key={item.id} value={item.id}>{item.tutor_usuario_nombre !== null ? `[${item.tutor_usuario_nombre}]` : ''} {item.descripcion}</MenuItem>)
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.descripcion}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3} style={{}}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                  style={{ height: 56 }}
                  onClick={() => handleAsignarGrupo(grupoSeleccionado)}
                >
                  Asignar grupo
                </Button>
              </Grid>
              
              <Grid item xs={12} md={6} lg={3} style={{}}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    style={{ paddingLeft: 20 }}
                    id="plantilla_permisos_id-label"
                  >
                    Plantilla de permisos
                  </InputLabel>

                  <Select
                    labelId="plantilla_permisos_id-label"
                    id="plantilla_permisos_id"
                    name="plantilla_permisos_id"
                    label="Plantilla de permisos"
                    fullWidth
                    value={plantillaPermisosSeleccionada}
                    onChange={(value) => {
                      setPlantillaPermisosSeleccionada(value.target.value);
                    }}
                  >
                    {plantillaPermisos &&
                      plantillaPermisos.map((item) => {
                        // return (<MenuItem key={item.id} value={item.id}>{item.tutor_usuario_nombre !== null ? `[${item.tutor_usuario_nombre}]` : ''} {item.descripcion}</MenuItem>)
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nombre}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3} style={{}}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                  style={{ height: 56 }}
                  onClick={() => handleAsignarPlantillaPermisos(plantillaPermisosSeleccionada)}
                >
                  Asignar permisos
                </Button>
              </Grid>

              {/* <Grid item xs={12} md={6} lg={2} style={{}}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    style={{ paddingLeft: 20 }}
                    id="plantilla_id-label"
                  >
                    Grupo de alumnos
                  </InputLabel>

                  <Select
                    labelId="plantilla_id-label"
                    id="plantilla_id"
                    name="plantilla_id"
                    label="Grupo"
                    fullWidth
                    value={plantillaSeleccionada}
                    onChange={(value) => {
                      setPlantillaSeleccionada(value.target.value);
                    }}
                  >
                    {plantillasAsignables &&
                      plantillasAsignables.map((item) => {
                        // return (<MenuItem key={item.id} value={item.id}>{item.tutor_usuario_nombre !== null ? `[${item.tutor_usuario_nombre}]` : ''} {item.descripcion}</MenuItem>)
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.descripcion}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={2} style={{}}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                  style={{ height: 56 }}
                  onClick={() => handleAsignarPlantilla(plantillaSeleccionada)}
                >
                  Asignar grupo
                </Button>
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={2} style={{}}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel style={{ paddingLeft: 20 }} id="sede_id-label">
                    Sedes
                  </InputLabel>

                  <Select
                    labelId="sede_id-label"
                    id="sede_id"
                    name="sede_id"
                    label="Sede"
                    fullWidth
                    value={sedeSeleccionada}
                    onChange={(value) => {
                      setSedeSeleccionada(value.target.value);
                    }}
                  >
                    {sedesAsignables &&
                      sedesAsignables.map((item) => {
                        // return (<MenuItem key={item.id} value={item.id}>{item.tutor_usuario_nombre !== null ? `[${item.tutor_usuario_nombre}]` : ''} {item.descripcion}</MenuItem>)
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nombre}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={2} style={{}}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                  style={{ height: 56 }}
                  onClick={() => handleAsignarSede(sedeSeleccionada)}
                >
                  Asignar sede
                </Button>
              </Grid> */}
            </Grid>

            <div style={{ height: 500, width: "100%", padding: "0 15px" }}>
              {
                loadingDatagrid ? <Box style={{width: "100%", textAlign: "center"}}>Refrescando datos... </Box>:
                <DataGridPro
                pagination={false}
                rows={alumnos}
                columns={columnas}
                rowHeight={30}
                checkboxSelection
                disableSelectionOnClick
                disableDensitySelector
                onStateChange={(item) => setSelection(item.rowSelection)}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{ Toolbar: GridToolbar }}
                filterModel={ filterModel }
                onFilterModelChange={ onFilterModelChange }
              />}
            </div>
          </Paper>
        </Box>
      </>
    ) : (
      <></>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div style={{ marginTop: "80px" }}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
