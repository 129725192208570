import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import * as yup from "yup";
import { useFormik } from "formik";
import { getListaDetalle } from "../../../services/lista_detalle";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { actualizar_documento_creadores, getUsuariosCreadores, insertar_documento_creadores } from "../../../services/alumnos";

export const DialogDocumentoForm = ({
  openModal,
  setOpenModal,
  handleCloseDialog,
  documentoSeleccionado
}) => {
  const [tiposDocumentos, setTiposDocumentos] = useState("");
  const [usuariosCreadores, setUsuariosCreadores] = useState([])
  const [comunidades, setComunidades] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [cursos, setCursos] = useState([]);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  /* -------------------------------------------------------------------------- */
  /*                            useEffect para update                           */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getListaDetalle({
      usuario_id: usuarioSeleccionado.id,
      lista_id: "26",
    }).then((response) => {
      setTiposDocumentos(response.lista_detalle);
    });
    
    getListaDetalle({
      usuario_id: usuarioSeleccionado.id,
      lista_id: "30",
    }).then((response) => {
      setCursos(response.lista_detalle);
    });
    
    getListaDetalle({
      usuario_id: usuarioSeleccionado.id,
      lista_id: "3",
    }).then((response) => {
      setComunidades(response.lista_detalle);
    });

    getListaDetalle({
      usuario_id: usuarioSeleccionado.id,
      lista_id: "1",
    }).then((response) => {
      setEspecialidades(response.lista_detalle);
    });

    getUsuariosCreadores({usuario_id: usuarioSeleccionado.id}).then( response => {
      setUsuariosCreadores(response.usuarios)
    })
  }, []);

  useEffect(() => {

    formik.setValues({
      nombre: "",
      url: "",
      fecha_inicio: null,
      fecha_limite: null,
      descripcion: "",
      tipo_id: "",
      creador_id: "", 
      comunidad_autonoma_id: "",
      especialidad_id: "",
      curso_id: "",
      orden: "",
      descripcion_creador: "",
    })

    if(documentoSeleccionado) {
      formik.setValues({
        nombre: documentoSeleccionado.nombre,
        url: documentoSeleccionado.url,
        fecha_inicio: documentoSeleccionado.fecha_inicio,
        fecha_limite: documentoSeleccionado.fecha_limite,
        descripcion: documentoSeleccionado.descripcion,
        tipo_id: documentoSeleccionado.tipo_id,
        creador_id: documentoSeleccionado.creador_id,
        comunidad_autonoma_id: documentoSeleccionado.comunidad_autonoma_id,
        especialidad_id: documentoSeleccionado.especialidad_id,
        curso_id: documentoSeleccionado.curso_id,
        orden: documentoSeleccionado.orden,
      })

    }
  
  }, [documentoSeleccionado])

  const formik = useFormik({
    initialValues: {
      nombre: "",
      url: "",
      fecha_limite: null,
      fecha_inicio: null,
      descripcion: "",
      tipo_id: "",
      creador_id: "",
      comunidad_autonoma_id: "",
      especialidad_id: "",
      curso_id: "",
      orden: "",
      descripcion_creador: "",
    },
    validationSchema: yup.object({
      nombre: yup.string().required("Obligatorio"),
      url: yup.string().required("Obligatorio"),
      fecha_limite: yup.string().nullable().required("Obligatorio"),
      // descripcion: yup.string().required("Obligatorio"),
      tipo_id: yup.string().required("Obligatorio"),
      comunidad_autonoma_id: yup.string().required("Obligatorio"),
      especialidad_id: yup.string().required("Obligatorio"),
      curso_id: yup.string().required("Obligatorio"),
      descripcion_creador: documentoSeleccionado ? yup.string() : yup.string().required("Obligatorio"), 
    }),
    onSubmit: (values) => {

      if(documentoSeleccionado) {
        actualizar_documento_creadores({
          documento_id: documentoSeleccionado.id,
          usuario_id: usuarioSeleccionado.id,
          nombre: values.nombre,
          url: values.url,
          fecha_inicio: values.fecha_inicio,
          fecha_limite: values.fecha_limite,
          descripcion: values.descripcion,
          tipo_id: values.tipo_id,
          comunidad_autonoma_id: values.comunidad_autonoma_id,
          especialidad_id: values.especialidad_id,
          curso_id: values.curso_id,
          orden: values.orden,
      }).then( (response) => {
          handleCloseDialog()
      })
      } else {
          insertar_documento_creadores({
              usuario_id: usuarioSeleccionado.id,
              nombre: values.nombre,
              url: values.url,
              fecha_inicio: values.fecha_inicio,
              fecha_limite: values.fecha_limite,
              descripcion: values.descripcion,
              tipo_id: values.tipo_id,
              creador_id: values.creador_id,
              comunidad_autonoma_id: values.comunidad_autonoma_id,
              especialidad_id: values.especialidad_id,
              curso_id: values.curso_id,
              orden: values.orden,
              descripcion_creador: values.descripcion_creador,
          }).then( (response) => {
              handleCloseDialog()
          })
      }
    },
  });

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      maxWidth={"xl"}
      fullWidth
    >
      <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

        <DialogTitle>{documentoSeleccionado ? "Actualizar documento" : "Crear documento"}</DialogTitle>
        <DialogContent style={{  }}>
          <Grid container spacing={2}>

            {/* Curso */}
            <Grid item sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ }}
                error={formik.touched.curso_id && Boolean(formik.errors.curso_id)}
              >
                <InputLabel id="curso_id-label">Curso</InputLabel>
                <Select
                  labelId="curso_id-label"
                  id="curso_id"
                  name="curso_id"
                  label="Curso"
                  fullWidth
                  value={formik.values.curso_id}
                  onChange={formik.handleChange}
                  error={formik.touched.curso_id && Boolean(formik.errors.curso_id)}
                >
                  {cursos &&
                    cursos.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.curso_id && formik.errors.curso_id}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Orden */}
            <Grid item sm={6}>
              <TextField
                id="orden"
                name="orden"
                label="Clase"
                placeholder="Introduce la clase"
                fullWidth
                margin="normal"
                value={formik.values.orden}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.orden && Boolean(formik.errors.orden)}
                helperText={formik.touched.orden && formik.errors.orden}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: 0 }}
              />
            </Grid>

            {/* Tipo de documento */}
            <Grid item sm={4}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ }}
                error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
              >
                <InputLabel id="tipo_id-label">Tipo de documento</InputLabel>
                <Select
                  labelId="tipo_id-label"
                  id="tipo_id"
                  name="tipo_id"
                  label="Tipo de documento"
                  fullWidth
                  value={formik.values.tipo_id}
                  onChange={formik.handleChange}
                  error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
                >
                  {tiposDocumentos &&
                    tiposDocumentos.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.tipo_id && formik.errors.tipo_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            
            {/* Comunidad */}
            <Grid item sm={4}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{  }}
                error={formik.touched.comunidad_autonoma_id && Boolean(formik.errors.comunidad_autonoma_id)}
              >
                <InputLabel id="comunidad_autonoma_id-label">Comunidad autónoma</InputLabel>
                <Select
                  labelId="comunidad_autonoma_id-label"
                  id="comunidad_autonoma_id"
                  name="comunidad_autonoma_id"
                  label="Comunidad autónoma"
                  fullWidth
                  value={formik.values.comunidad_autonoma_id}
                  onChange={formik.handleChange}
                  error={formik.touched.comunidad_autonoma_id && Boolean(formik.errors.comunidad_autonoma_id)}
                >
                  {comunidades &&
                    comunidades.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.comunidad_autonoma_id && formik.errors.comunidad_autonoma_id}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Especialidad */}
            <Grid item sm={4}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ }}
                error={formik.touched.especialidad_id && Boolean(formik.errors.especialidad_id)}
              >
                <InputLabel id="especialidad_id-label">Especialidad</InputLabel>
                <Select
                  labelId="especialidad_id-label"
                  id="especialidad_id"
                  name="especialidad_id"
                  label="Especialidad"
                  fullWidth
                  value={formik.values.especialidad_id}
                  onChange={formik.handleChange}
                  error={formik.touched.especialidad_id && Boolean(formik.errors.especialidad_id)}
                >
                  {especialidades &&
                    especialidades.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.especialidad_id && formik.errors.especialidad_id}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Fecha de inicio */}
            <Grid item sm={6} style={{paddingRight: 10}}>
                              
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                    
                        fullWidth
                        id="fecha_inicio"
                        label="Fecha Inicio"
                        placeholder='Introduce la fecha de inicio'
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={formik.values.fecha_inicio}
                        onChange={value => formik.setFieldValue("fecha_inicio", value)}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        error={formik.touched.fecha_inicio && Boolean(formik.errors.fecha_inicio)}
                        helperText={formik.touched.fecha_inicio && formik.errors.fecha_inicio}
                    />
                </MuiPickersUtilsProvider>

            </Grid>
            
            {/* Fecha limite */}
            <Grid item sm={6} style={{paddingRight: 10}}>
                              
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                    
                        fullWidth
                        id="fecha_limite"
                        label="Fecha Límite"
                        placeholder='Introduce la fecha límite'
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={formik.values.fecha_limite}
                        onChange={value => formik.setFieldValue("fecha_limite", value)}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        error={formik.touched.fecha_limite && Boolean(formik.errors.fecha_limite)}
                        helperText={formik.touched.fecha_limite && formik.errors.fecha_limite}
                    />
                </MuiPickersUtilsProvider>

            </Grid>
            
            {/* Nombre */}
            <Grid item sm={12}>
              <TextField
                id="nombre"
                name="nombre"
                label="Nombre"
                placeholder="Introduce el nombre"
                fullWidth
                margin="normal"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: 8 }}
              />
            </Grid>

            {/* URL */}
            <Grid item sm={12}>
              <TextField
                id="url"
                name="url"
                label="Url"
                placeholder="Introduce la URL"
                fullWidth
                margin="normal"
                value={formik.values.url}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: 8 }}
              />
            </Grid>

            {/* Descripcion interna */}
            <Grid item sm={12}>
              <TextField
                id="descripcion"
                name="descripcion"
                label="Descripcion interna"
                placeholder="Introduce la descripcion"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.descripcion && Boolean(formik.errors.descripcion)
                }
                helperText={formik.touched.descripcion && formik.errors.descripcion}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: 8 }}
              />
            </Grid>

            <Divider />

            {/* Creador */}
            { 
              !documentoSeleccionado && 
              <Grid item sm={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ }}
                  error={
                    formik.touched.creador_id &&
                    Boolean(formik.errors.creador_id)
                  }
                >
                  <InputLabel id="creador_id-label">Creador</InputLabel>
                  <Select
                    labelId="creador_id-label"
                    id="creador_id"
                    name="creador_id"
                    label="Creador"
                    fullWidth
                    value={formik.values.creador_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.creador_id &&
                      Boolean(formik.errors.creador_id)
                    }
                  >
                    
                    {usuariosCreadores &&
                      usuariosCreadores.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {`${item.nombre} ${item.apellidos}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {formik.touched.creador_id &&
                      formik.errors.creador_id}
                  </FormHelperText>
                </FormControl>
              </Grid>
            }

            {/* Descripcion del creador */}
            {!documentoSeleccionado && 
            <Grid item sm={12}>
              <TextField
                id="descripcion_creador"
                name="descripcion_creador"
                label="Descripcion creador"
                placeholder="Introduce la descripcion"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                value={formik.values.descripcion_creador}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.descripcion_creador && Boolean(formik.errors.descripcion_creador)
                }
                helperText={formik.touched.descripcion_creador && formik.errors.descripcion_creador}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: 8 }}
              />
            </Grid>}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
          <Button type="submit">{ documentoSeleccionado ? "Actualizar documento" : "Crear documento"}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
