import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

import LoadingComponent from "../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
// import Swal from "sweetalert2";
import { Stack } from "@mui/material";
import { Box, Button, Chip, Divider, Link, Typography } from "@material-ui/core";
import DataGridInnovattio from "../../../components/DataGridInnovatiio";
import QuickSearchToolbar from "../../../components/QuickSearchToolbar";
import { requestSearch } from "../../../utils/regExp";
import { eliminar_documento_creadores, getDocumentos } from "../../../services/alumnos";
import { DialogDocumentoForm } from "./DialogDocumentoForm";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { blue, green, grey, red } from "@material-ui/core/colors";
import Swal from "sweetalert2";
import useDataGridFilterPersist from "../../../hooks/useDataGridFilterPersist";


// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
  emptyImage: {
    width: "100%",
  },

  enlace: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
}));

export default function DocumentosContent() {
  const classes = useStyles();

  const [documentos, setDocumentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  // // const [plantillaSeleccionadaId, setPlantillaSeleccionadaId] = useState("");

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const history = useHistory();

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando documentos");

    cargarDocumentos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                              Cargar Plantillas                             */
  /* -------------------------------------------------------------------------- */
  const cargarDocumentos = () => {

    getDocumentos({usuario_id: usuarioSeleccionado.id}).then( response => {
      setLoading(false);
      response && setDocumentos(response.documentos);
    })

  };

  // const CheckUrl = (url) => {
  //   console.log(url.url)
  //   const [status, setStatus] = useState(null);
  
  //   const handleCheckUrl = async () => {
  //     try {
  //       // Hacemos una petición HEAD para no descargar el contenido completo
  //       const response = await fetch(url.url, { method: 'HEAD' });
  //       // Si response.ok es true, significa que la respuesta fue 2xx (200-299)
  //       console.log(response)
  //       setStatus(response.ok ? 'existe' : 'no-existe');
  //     } catch (error) {
  //       // Aquí caemos si fetch lanza una excepción (e.g. error de red)
  //       setStatus('error');
  //     }
  //   };
    
  //   useEffect(() => {
  //     handleCheckUrl();
  //   } , [url]);

  //   return (
  //     <div style={{ margin: '20px' }}>
  //       {status === 'existe' && <p style={{ color: 'green' }}>La URL existe (respuesta 2xx)</p>}
  //       {status === 'no-existe' && <p style={{ color: 'red' }}>La URL no existe o devuelve error</p>}
  //       {status === 'error' && <p style={{ color: 'red' }}>Error al verificar la URL</p>}
  //     </div>
  //   );
  // }
  
  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */
  const columnas = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "tipo_nombre", headerName: "Tipo", minWidth: 150 },
    { field: "curso_nombre", headerName: "Curso", minWidth: 150 },
    { field: "orden", headerName: "Clase", minWidth: 150, renderCell: (cellValues) => {
      return (
        cellValues.row.orden === "0" ? "" : cellValues.row.orden
        );
      } 
    },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", minWidth: 150 },
    { field: "especialidad_nombre", headerName: "Especialidad", minWidth: 150 },
    { field: "fecha_inicio", headerName: "Fecha inicio", minWidth: 170 },
    { field: "fecha_limite", headerName: "Fecha límite", minWidth: 170 },
    { field: "nombre", headerName: "Nombre", minWidth: 370 },
    { field: "url", headerName: "URL", flex: 1, minWidth: 370, renderCell: (cellValues) => {
      
      return (
        <> 
          {
            // <CheckUrl url={cellValues.row.url} />
            cellValues.row.url && <Link href={cellValues.row.url} target="_blank" rel="noreferrer" style={{color: 'blue'}}>{cellValues.row.url}</Link>
          }    
        </>
        )
        }
    },
    { field: "creadores", headerName: "Creadores", minWidth: 200 },
    { field: "num_creadores", headerName: "Num Creadores", minWidth: 80 },
    { field: "estado", headerName: "Estado", minWidth: 150, renderCell: (cellValues) => {
      return (
        <>
        { cellValues.row.num_creadores > 0 && cellValues.row.num_creadores === cellValues.row.num_items_terminado && <Chip label="Terminado" size="small" style={{backgroundColor: green[400], color: 'white'}}></Chip>} 
        { cellValues.row.num_creadores === "0" && <Chip label="Sin creadores" size="small" style={{backgroundColor: grey[400], color: 'white'}}></Chip>} 
        { ( cellValues.row.num_creadores > 0 && cellValues.row.num_creadores === cellValues.row.num_items_pendiente) && <Chip label="Pendiente" size="small" style={{backgroundColor: red[400], color: 'white'}}></Chip>} 
        { cellValues.row.num_items_en_proceso >= 1 && <Chip label="En proceso" size="small" style={{backgroundColor: blue[400], color: 'white'}}></Chip>}
        </>
      )
    } },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          label="Creadores"
          onClick={ () => { 
            history.push(`/gestion-documentos/creadores/${params.id}`);
          }}
          showInMenu
        />,
        <GridActionsCellItem divider showInMenu/>,
        <GridActionsCellItem
          label="Eliminar documento"
          onClick={ () => { 
            Swal.fire({
              title: '¿Realmente deseas eliminar el documento? La operación no se puede deshacer.',
              showCancelButton: true,
              confirmButtonText: 'Eliminar',
              cancelButtonText: `Cancelar`,
              confirmButtonColor: '#afcb0d',
              cancelButtonColor: '#a5a5a5',
              icon: 'warning',
              reverseButtons: true
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                eliminar_documento_creadores({usuario_id: usuarioSeleccionado.id, documento_id: params.id}).then( response => {
                  cargarDocumentos()
                })
              } else if (result.isDenied) {
              //   Swal.fire('Changes are not saved', '', 'info')
              }
            })

            
          }}
          showInMenu
        />,
        // <GridActionsCellItem
        //   label="Pasar a histórico"
        //   onClick={ () => { 
        //   }}
        //   showInMenu
        // />,
      ],
    }
  ];

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */

  const Contenido = () => {
    const [rows, setRows] = useState(documentos);
    const [searchText, setSearchText] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useState(false);
    
    const handleCloseDialog = () => {
      setOpenModal(false)
      cargarDocumentos()
    }
    const [filterModel, onFilterModelChange] = useDataGridFilterPersist('documentos_page','documentos')

    return (
      <>
        <Stack direction={"row"} spacing={2}>
          <Box mt={0} mb={0} mx={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Gestión de documentos
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDocumentoSeleccionado(null);
              setOpenModal(true)}
            }
          >
            Añadir nuevo documento
          </Button>
        </Stack>

        
        <Box>
          <div style={{ height: 700, width: "100%", padding: "0 15px" }}>
            <DataGridInnovattio
              rows={rows}
              columns={columnas}
              rowHeight={30}
              components={{ Toolbar: QuickSearchToolbar }}
              onDoubleClick={(item) => {
                setDocumentoSeleccionado(item);
                setOpenModal(true)
              }}
              filterModel={ filterModel }
              onFilterModelChange={ onFilterModelChange }
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) =>
                    requestSearch(
                      documentos,
                      setSearchText,
                      setRows,
                      event.target.value
                    ),
                  clearSearch: () =>
                    requestSearch(documentos, setSearchText, setRows, ""),
                },
              }}
            />

            
            <DialogDocumentoForm
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleCloseDialog={handleCloseDialog}
              documentoSeleccionado={documentoSeleccionado}
            />

          </div>
        </Box>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
